<template>
  <div class="font-stolzl">
    <div class="min-h-screen">
      
      <!-- SPACE-->
      <div id="space" class="md:h-16"></div>
      <!-- SPACE-->
      
      <!-- HEADING-->
      <div v-if="content?.page" class="w-auto h-auto overflow-hidden">
        <div :ref="setRef" data-anim="0" class="break-words font-light text-coolGray-900 mt-5 md:mt-28 mb-5 text-4xl md:text-5xl px-4 md:px-8 opacity-0">{{content.page.name}}</div>
      </div>
      <!-- HEADING-->
      
      <!-- CONTENT-->
      <div v-if="content?.page && contentImages?.page" class="mt-2">
        <div v-if="content.page.pageContentBlocks.length > 0">
          <div v-for="(block,index) in content.page.pageContentBlocks" class="w-screen h-auto mb-10 md:mb-0 md:h-screen flex flex-col md:flex-row justify-between items-start" :class="{'md:flex-row-reverse' : index%2 !== 0}">
            <div class="w-full md:w-1/2 h-75 pl-4 md:pl-8 pr-4 md:pr-4 opacity-0" :class="{'pl-4 md:pl-4 pr-4 md:pr-8' : index%2 !== 0}" :ref="setRef" data-anim="0">
              <div v-if="contentImages?.page?.pageContentBlocks[index]?.image?.url" class="relative w-full h-full bg-cover bg-center" :style="{ backgroundImage: 'url(' + contentImages.page.pageContentBlocks[index].image.url + ')' }">
                <div class="absolute bottom-5 left-5 w-auto h-auto overflow-hidden">
                  <div class="w-1/2 leading-snug font-light text-base md:text-sm text-coolGray-50 whitespace-pre">{{contentImages.page.pageContentBlocks[index].imageCredits}}</div>
                </div> 
              </div>
            </div>
            <div class="w-full md:w-1/2 h-full flex flex-col pl-4 md:pl-8 pr-4 md:pr-16 opacity-0" :class="{'pl-4 md:pl-8 pr-4 md:pr-4' : index%2 !== 0}" :ref="setRef" data-anim="0">
              <div v-if="block.heading" class="font-normal mt-8 md:mt-0 md:font-bold leading-8 text-2xl w-full md:w-3/4 md:text-3xl text-coolGray-900">{{block.heading}}</div>
              <div v-if="block.text" class="font-light text-base text-coolGray-900 mt-5 w-full md:w-3/4 whitespace-pre-line">{{block.text}}</div>
              <div v-if="block.link && block.routerName && block.linkText" class="flex flex-row justify-start items-center mt-2 cursor-pointer group" @click="internalNav(block.routerName, block.link)">
                <div class="font-normal text-base  text-coolGray-900">{{block.linkText}}</div>
                <svg xmlns="http://www.w3.org/2000/svg" class="transition duration-200 ease-out h-4 w-4 text-coolGray-900 transform group-hover:translate-x-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                </svg>
              </div> 
              <a v-if="block.externalLink && block.linkText" class="flex flex-row justify-start items-center mt-2 cursor-pointer group" :href="block.externalLink" target="_blank">
                <div class="font-normal text-base  text-coolGray-900">{{block.linkText}}</div>
                <svg xmlns="http://www.w3.org/2000/svg" class="transition duration-200 ease-out h-4 w-4 text-coolGray-900 transform group-hover:translate-x-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                </svg>
              </a>
              </div>
            
          </div>
        </div>
      </div>
      <!-- CONTENT-->
      
    </div>
    <!-- COMPONENTS-->
    <Footer />
    <getContentImagesQuery />
    <getContentQuery />
  </div>
</template>

<script>
import scrollToTop from '../mixins/scrollToTop.vue';
import intersectionObserver from '../mixins/intersectionObserver.vue';

import Footer from '../components/footer.vue';
import getContentImagesQuery from '../graphql/graphcms/getContentImagesQuery.vue';
import getContentQuery from '../graphql/graphcms/getContentQuery.vue';


export default {
  name: 'content',
  props: ['slug'],
  components: { Footer, getContentImagesQuery, getContentQuery },
  mixins: [ scrollToTop, intersectionObserver ],

  computed: {
    seo(){
      return this.$store.state.data.seo;
    },
    contentImages(){
      return this.$store.state.data.contentImages;
    },
    content(){
      return this.$store.state.data.content;
    },
    lang(){
      return this.$store.state.lang.currentLang;
    },
  },
  
  watch: {
    seo(){
      document.title = this.seo?.metaTitle;
      document.querySelector('meta[name="description"]').setAttribute("content", this.seo?.metaDescription);
    },
    $route(to, from) {
      if(to.name == from.name){
        this.$store.commit('setContent', to.params.slug)
        window.scrollTo({top: 0, behavior: 'smooth'});
      }
    }
  },
  
  mounted(){
    this.$store.commit('setContent', this.slug);
  },
  
   
  methods:{
    
    internalNav(routerName, link){
      this.$router.push({ name: routerName, params: { slug: link } });
    },

  }
}
      
</script>