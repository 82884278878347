<template></template>

<script>
import { useQuery, useClient } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';
import { computed, reactive } from 'vue';

export default {
  name:"getContentQuery",
  
  watch: {
    content() {
      this.$store.commit('graphqlData', { item: 'content', result: this.content });
      this.$store.commit('graphqlData', { item: 'seo', result: this.content.page.seo });
     },
  },
    
  setup() {
    const store = useStore();
    
    useClient({
      url: process.env.VUE_APP_GRAPHCMS_URL,
      cachePolicy: 'network-only'
    });

    const variables = reactive({
      slug: computed(() => store.state.data.contentSlug),
      lang: computed(() => store.state.lang.currentLang)
    });
    
    const getContentQuery = gql `
      query MyQuery($slug: String!, $lang:Locale!) {
          page(where: {slug: $slug}) {
            name
            seo {
              metaDescription
              metaTitle
            }
            pageContentBlocks(locales: [$lang]) {
              linkText
              link
              routerName
              externalLink
              text
              heading
            }
          }
        }
    `;
    
    const { data: content } = useQuery({
      query: getContentQuery,
      variables
    });
    
    return { content };
  },
}
</script>