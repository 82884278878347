<template></template>

<script>
import { useQuery, useClient } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';
import { computed, reactive } from 'vue';

export default {
  name:"getContentImagesQuery",
  
  watch: {
    contentImages() {
      this.$store.commit('graphqlData', { item: 'contentImages', result: this.contentImages });
     },
  },
    
  setup() {
    const store = useStore();
    
    useClient({
      url: process.env.VUE_APP_GRAPHCMS_URL,
    });

    const variables = reactive({
      slug: computed(() => store.state.data.contentSlug),
    });
    
    const getContentImagesQuery = gql `
      query MyQuery($slug: String!) {
          page(where: {slug: $slug}) {
            name
            pageContentBlocks {
              image {
                url(transformation: {document: {output: {format: webp}}, image: {resize: {width: 1000}}})
              }
              imageCredits
            }
          }
        }
    `;
    
    const { data: contentImages } = useQuery({
      query: getContentImagesQuery,
      variables
    });
    
    return { contentImages };
  },
}
</script>